<template>
  <div>
    <div class="login-header">
      <el-row>
        <el-col :span="18">
          <a href="/">
            <img src="../assets/images/logow.png" alt="" />
          </a>
        </el-col>
        <el-col :span="6">
          <div class="phone-box">
            <span class="icon-phone-square"></span>
            <div class="service-titme">
              <p class="titme-item">客服热线(每日8：30-20：30)</p>
              <p class="phone-item">400-1020-925</p>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="login-box">
      <div v-if="loginstatus" class="login-form">
        <h2 class="title">学员登录</h2>
        <el-tabs v-model="logintype" type="border-card">
          <el-tab-pane name="phonelogin" label="手机动态码登录">
            <el-form
              :model="phoneForm"
              status-icon
              ref="phoneForm"
              class="demo-loginForm"
            >
              <el-form-item prop="loginname">
                <el-input
                  v-model="phoneForm.loginname"
                  prefix-icon="icon-mobile-phone"
                  placeholder="请输入手机号"
                ></el-input>
              </el-form-item>
              <el-form-item prop="code">
                <el-input
                  v-model="phoneForm.code"
                  placeholder="请输入验证码"
                  prefix-icon="icon-code2"
                  style="width:56%"
                ></el-input>
                <a class="code-btn" style="width:40%" @click="getcode(1)"
                  >获取验证码</a
                >
              </el-form-item>
              <el-form-item></el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  style="width:100%"
                  @click="phoneSubmitForm('phoneForm')"
                  >登录
                </el-button>
              </el-form-item>
              <el-form-item style="text-align: center;">
                <el-button type="text" style="color: #f00;" @click="setnewpwd">
                  新学员，设置密码？
                </el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane name="idlogin" label="账号密码登录">
            <el-form
              :model="ruleForm"
              status-icon
              :rules="rules"
              ref="ruleForm"
              class="demo-loginForm"
            >
              <el-form-item prop="idcard">
                <el-input
                  v-model="ruleForm.idcard"
                  prefix-icon="el-icon-postcard"
                  placeholder="账号/有效身份证件号"
                ></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input
                  show-password
                  v-model="ruleForm.password"
                  prefix-icon="icon-locks"
                  placeholder="请您输入密码"
                ></el-input>
              </el-form-item>
              <el-form-item v-if="codeIsshow">
                <el-row :gutter="20">
                  <el-col :span="16">
                    <el-input
                      v-model="ruleForm.code"
                      placeholder="输入验证码"
                      prefix-icon="icon-code2"
                    ></el-input>
                  </el-col>
                  <el-col :span="6" style="text-align: right;">
                    <div
                      class="login-code"
                      style="margin-top:4px"
                      @click="refreshCode"
                    >
                      <!--验证码-->
                      <Identify :identifyCode="identifyCode"></Identify>
                    </div>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item>
                <el-row :gutter="20">
                  <el-col :span="6">
                    <el-checkbox @change="changecheck" v-model="checked"
                      >记住密码</el-checkbox
                    >
                  </el-col>
                  <el-col :span="6" :offset="12" style="text-align: right;">
                    <a class="forgot-password" @click="forgotDialog = true">
                      忘记密码
                    </a>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item>
                <el-button
                  :loading="is_loading"
                  type="primary"
                  style="width:100%"
                  @click="submitForm('ruleForm')"
                  >登录
                </el-button>
              </el-form-item>
              <el-form-item style="text-align: center;">
                <el-button type="text" style="color: #f00;" @click="setnewpwd">
                  新学员，设置密码？
                </el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div v-if="supply" class="supplement-form">
        <h2 class="title">补充信息</h2>
        <el-form
          :model="supplyForm"
          status-icon
          :rules="supplyrules"
          ref="supplyForm"
          class="demo-ruleForm"
        >
          <el-form-item prop="city" class="driving-school">
            <el-cascader
              v-model="supplyForm.city_id"
              placeholder="请选择驾校所在地"
              :options="citydata"
              :props="cityParams"
              @change="handleChangecity"
            >
            </el-cascader>
          </el-form-item>
          <el-form-item prop="licence" class="select-models">
            <el-cascader
              v-model="licence"
              placeholder="选择车型"
              :options="licencedata"
              :props="defaultParams"
              @change="handleChangelicence"
            >
            </el-cascader>
          </el-form-item>
          <el-form-item prop="idcard">
            <el-input
              v-model="supplyForm.idcard"
              prefix-icon="el-icon-postcard"
              placeholder="身份证号码"
            ></el-input>
          </el-form-item>
          <!--          <el-form-item prop="idCard">-->
          <!--            <el-input-->
          <!--              v-model="ruleForm.idCard"-->
          <!--              prefix-icon="icon-mobile-phone"-->
          <!--              placeholder="驾校报名时所用手机号"-->
          <!--            ></el-input>-->
          <!--          </el-form-item>-->
          <el-form-item prop="name">
            <el-input
              v-model="supplyForm.realname"
              placeholder="姓名"
              prefix-icon="el-icon-user"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              :loading="subloading"
              type="success"
              @click="supplySubmitForm('supplyForm')"
              style="width:100%"
              >保存
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="login-copy">
      <p>
        车学堂 Copyright@2021 人民交通出版社股份有限公司 All Rights Reserved |
        办公地址:北京市朝阳区小营北路17号 | 联系邮箱:hanchao@ccpress.com.cn |
        客服热线:400-1020-925
      </p>
      <p>
        <a style="color:#666666" href="https://beian.miit.gov.cn/">
          京ICP备15018898号-14
        </a>
      </p>
    </div>
    <el-dialog
      title="忘记密码"
      :visible.sync="forgotDialog"
      width="52%"
      center
      :close-on-click-modal="false"
    >
      <el-steps :active="active" align-center finish-status="success">
        <el-step title="输入账号"></el-step>
        <el-step title="设置新密码"></el-step>
        <el-step title="完成"></el-step>
      </el-steps>
      <div class="step-item">
        <el-tabs v-model="activeName">
          <el-tab-pane name="0">
            <el-form
              ref="form"
              :model="form"
              label-width="90px"
              class="forgot-box"
            >
              <el-form-item label="手机号码：">
                <el-input v-model="form.phone"></el-input>
              </el-form-item>
              <el-form-item label="验证码：">
                <el-input v-model="form.code" style="width:56%"></el-input>
                <a class="code-btn" style="width:40%" @click="getcode(2)">
                  获取验证码
                </a>
              </el-form-item>
              <el-form-item label="滑块验证：">
                <drag-verify
                  :width="dragVerify.width"
                  :height="dragVerify.height"
                  :text="dragVerify.text"
                  :success-text="dragVerify.successText"
                  :background="dragVerify.background"
                  :progress-bar-bg="dragVerify.progressBarBg"
                  :completed-bg="dragVerify.completedBg"
                  :handler-bg="dragVerify.handlerBg"
                  :handler-icon="dragVerify.handlerIcon"
                  :text-size="dragVerify.textSize"
                  :success-icon="dragVerify.successIcon"
                  :circle="dragVerify.getShape"
                  @passcallback="passcallback"
                  ref="Verify"
                >
                </drag-verify>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane name="1">
            <el-form
              :rules="validateRules"
              ref="form"
              :model="form"
              label-width="100px"
              class="forgot-box"
            >
              <el-form-item label="新密码：" prop="newpwd">
                <el-input v-model="form.newpwd"></el-input>
              </el-form-item>
              <el-form-item label="确认密码：" prop="fixnewpwd">
                <el-input v-model="form.fixnewpwd"></el-input>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane name="2">
            <div class="change-alt">
              <span class="icon-checkmark1"></span>
              <p>修改完成，请重新登录！</p>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button style="margin-top: 12px;" @click="next">下一步</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="设置密码"
      :visible.sync="setDialog"
      width="40%"
      center
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <el-form
        ref="setform"
        :model="setform"
        label-width="90px"
        class="forgot-box"
      >
        <el-form-item label="账号：">
          <el-input v-model="setform.idcard" placeholder="请输入身份证号/账号">
          </el-input>
        </el-form-item>
        <el-form-item label="手机号码：">
          <el-input v-model="setform.phone" placeholder="请输入手机号">
          </el-input>
        </el-form-item>
        <el-form-item label="验证码：">
          <el-input
            v-model="setform.code"
            style="width:56%"
            placeholder="请输入验证码"
          >
          </el-input>
          <a
            class="code-btn"
            v-if="is_sms"
            style="width:40%"
            @click="setgetcode()"
          >
            {{ buttonText }}
          </a>
          <a class="code-btn" v-if="is_time" style="width:40%">
            {{ timeText }}
          </a>
        </el-form-item>
        <el-form-item label="设置密码：">
          <el-input v-model="setform.password" placeholder="请输入要设置的密码">
          </el-input>
          <span style="color: #f00;">
            <!-- 注:密码设置8位，大小写字母数字和特殊字符,特殊字符限制 ! @ $ ^ ~ % -->
            注:密码必须为8~16位非空字符，并包含大小写字母、数字、特殊字符
          </span>
        </el-form-item>
        <el-form-item label="滑块验证：">
          <drag-verify
            :width="dragVerify.width"
            :height="dragVerify.height"
            :text="dragVerify.text"
            :success-text="dragVerify.successText"
            :background="dragVerify.background"
            :progress-bar-bg="dragVerify.progressBarBg"
            :completed-bg="dragVerify.completedBg"
            :handler-bg="dragVerify.handlerBg"
            :handler-icon="dragVerify.handlerIcon"
            :text-size="dragVerify.textSize"
            :success-icon="dragVerify.successIcon"
            :circle="dragVerify.getShape"
            @passcallback="setpasscallback"
            ref="setVerify"
          >
          </drag-verify>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button style="margin-top: 12px;" type="primary" @click="setsubmit">
          立即登录
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { Encrypt } from '../utils/secret.js'
import { login, register, resetpwd } from '../api/login'
import {
  getChecked,
  getIdcard,
  getLxLicenceId,
  getLxQuestionId,
  getLxSubjectId,
  getPassword,
  getResult,
  setChecked,
  setIdcard,
  setIsPay,
  setLicenceId,
  setLicenceName,
  setParentId,
  setPassword,
  setResult,
  setSubjectId,
  setTerm
} from '../utils/auth'
import {
  findpassword,
  getcode,
  updatepassword,
  userlicence,
  verifycode
} from '../api/user'
import Identify from '../components/identify'
import { getSubject } from '../api/courses'
import { getlicence } from '../api/licence'
import { getregion } from '../api/region'
import dragVerify from 'vue-drag-verify'

export default {
  components: { Identify, dragVerify },
  mounted() {
    // 初始化验证码
    this.identifyCode = ''
    this.makeCode(this.identifyCodes, 4)
  },
  data() {
    const checkAge = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('姓名不能为空'))
      }
    }
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('密码不能为空'))
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('身份证号码不能为空'))
      } else {
        callback()
      }
    }
    const validatepassword = (rule, value, callback) => {
      const pwpattent = /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_]).{8,16}$/
      if (!pwpattent.test(value)) {
        callback(
          new Error(
            '密码必须为8~16位非空字符，并包含大小写字母、数字、特殊字符'
          )
        )
      } else {
        callback()
      }
    }
    return {
      logintype: 'idlogin',
      is_slider: false,
      is_setslider: false,
      phoneForm: {
        loginname: '',
        code: '',
        type: 2
      },
      form: {
        phone: '',
        code: '',
        newpwd: '',
        fixnewpwd: ''
      },
      validateRules: {
        newpwd: [
          { required: true, message: '请填写密码', trigger: 'blur' },
          { required: true, trigger: 'blur', validator: validatepassword }
        ],
        fixnewpwd: [
          { required: true, message: '请填写密码', trigger: 'blur' },
          { required: true, trigger: 'blur', validator: validatepassword }
        ]
      },
      forgotDialog: false,
      dragVerify: {
        width: 250,
        height: 40,
        text: '按住滑块，拖抓到最右边',
        successText: '验证成功',
        progressBarBg: '#7AC23C',
        handlerIcon: 'icon-angle-double-right',
        handlerBg: '#fff',
        successIcon: 'icon-angle-double-right',
        textSize: '14px'
      },
      active: 0,
      value: null,
      num: 1,
      codeIsshow: false,
      subject_id: '',
      licence_name: '',
      identifyCodes: '1234567890abcdefjhijklinopqrsduvwxyz',
      identifyCode: '',
      loginstatus: true,
      supply: false,
      checked: true,
      ruleForm: {
        password: '',
        code: '',
        idcard: ''
      },
      rules: {
        password: [
          { validator: validatePass, trigger: 'blur' }
          // { min: 8, max: 16, message: '长度需要在8-16个字符之间' }
        ],
        idcard: [{ validator: validatePass2, trigger: 'blur' }],
        name: [{ validator: checkAge, trigger: 'blur' }]
      },
      supplyForm: {
        realname: '',
        province_id: '',
        city_id: '',
        licence_id: '',
        licence_title: '',
        idcard: ''
      },
      licence: [],
      supplyrules: {},
      citydata: [],
      licencedata: [],
      defaultParams: {
        label: 'licence_name',
        value: 'licence_id',
        children: 'children'
      },
      cityParams: {
        label: 'name',
        value: 'id',
        children: 'children'
      },
      is_loading: false,
      subloading: false,
      setDialog: false,
      setform: {
        idcard: '',
        phone: '',
        code: '',
        password: ''
      },
      qrcode: 0,
      timer: 0,
      is_sms: true,
      is_time: false,
      buttonText: '获取验证码',
      timeText: 120,
      countdown: 120, // 初始倒计时秒数
      isCounting: false // 是否正在倒计时
    }
  },
  created() {
    if (getChecked() !== undefined) {
      this.checked = JSON.parse(getChecked())
    } else {
      this.checked = getChecked()
    }
    if (this.checked === undefined) {
      this.ruleForm.idcard = ''
      this.ruleForm.password = ''
    } else if (this.checked === true) {
      this.ruleForm.idcard = getIdcard()
      this.ruleForm.password = getPassword()
    } else if (this.checked === false) {
      this.ruleForm.idcard = ''
      this.ruleForm.password = ''
    }
  },
  methods: {
    //手机号登录
    phoneSubmitForm() {
      login(this.phoneForm).then(response => {
        if (response.code === 0) {
          //最后在登录提交按钮中加入
          if (this.phoneForm.code == '') {
            this.$message.error('请填写验证码')
            return
          } else {
            setIsPay(response.result.is_pay)
            setTerm(response.result.term)
            setResult(response.result)
            this.cookieData(response.result.licence_id)
            getSubject({ licence_id: response.result.licence_id }).then(res => {
              if (res.code === 0) {
                if (res.result.subject_list.length > 0) {
                  setSubjectId(res.result.subject_list[0].subject_id)
                  //获取用户车型
                  userlicence().then(res => {
                    if (res.code === 0) {
                      //setLicenceName(res.result[0].data[0].licence_name)
                      //setTerm(res.result[0].data[0].term[0].term)
                      for (let i = 0; i < res.result.length; i++) {
                        for (let s = 0; s < res.result[i].data.length; s++) {
                          if (
                            JSON.parse(getResult()).licence_id ===
                            res.result[i].data[s].licence_id
                          ) {
                            setParentId(res.result[i].parent_id)
                            setLicenceName(res.result[i].data[s].licence_name)
                          }
                        }
                      }
                      //setParentId(res.result[0].parent_id)
                      // this.$router.push({ path: '/progress' })
                      if (getLxQuestionId() != undefined) {
                        this.$router.push({
                          path:
                            '/exam/subject?licence_id=' +
                            getLxLicenceId() +
                            '&subject_id=' +
                            getLxSubjectId()
                        })
                      } else {
                        this.$router.push({ path: '/progress' })
                      }
                    }
                  })
                }
              }
            })
          }
        } else {
          this.$message({
            message: response.msg,
            type: 'error'
          })
        }
      })
    },
    //滑块
    passcallback() {
      this.is_slider = this.$refs.Verify.isPassing
    },
    setpasscallback() {
      this.is_setslider = this.$refs.setVerify.isPassing
    },
    //获取手机号验证码
    getcode(type) {
      let phone = ''
      if (type == 2) {
        phone = this.form.phone
      }
      if (type == 1) {
        if (this.setDialog == true) {
          phone = this.setform.phone
        } else {
          phone = this.phoneForm.loginname
        }
      }
      let params = {
        type: type,
        phone: phone
      }
      if (this.setDialog == true) {
        params.idcard = this.setform.idcard
      }
      getcode(params).then(res => {
        this.qrcode = res.code
        if (res.code === 0 || res.code == 3) {
          this.$message({
            message: '短信发送成功！',
            type: 'success'
          })
        } else if (res.code == 2) {
          //服务端有账号且多次登录-》登录页
          this.$message({
            message: res.msg,
            type: 'error'
          })
          if (this.setDialog == true) {
            this.setDialog = false
          }
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    //下一步
    next() {
      if (this.active === 0) {
        if (this.is_slider === true) {
          let params = {
            phone: this.form.phone,
            type: 2,
            code: this.form.code
          }
          verifycode(params).then(res => {
            if (res.code === 0) {
              this.active++
            } else {
              if (res.code === 1) {
                this.$message({
                  message: '验证码错误！',
                  type: 'warning'
                })
              }
            }
          })
        } else {
          this.$message({
            message: '请完成滑块验证！',
            type: 'warning'
          })
        }
      } else if (this.active === 1) {
        let params = {
          phone: this.form.phone,
          newpwd: this.form.newpwd
        }
        this.$refs.form.validate(valid => {
          if (valid) {
            if (this.form.newpwd !== this.form.fixnewpwd) {
              this.$message({
                message: '密码不一致！',
                type: 'warning'
              })
              return false
            }
            findpassword(params).then(res => {
              if (res.code === 0) {
                this.active++
              } else {
                this.$message({
                  message: '密码修改失败！',
                  type: 'warning'
                })
              }
            })
          } else {
            return false
          }
        })
      } else if (this.active === 2) {
        this.active = 0
        this.forgotDialog = false
      }
    },
    //记住密码状态
    changecheck(val) {
      setChecked(val)
    },
    // 重置验证码
    refreshCode() {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length)
        ]
      }
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min)
    },
    submitForm(formName) {
      if (this.num === 3) {
        this.codeIsshow = true
      }
      if (this.checked) {
        setChecked(this.checked)
        setIdcard(this.ruleForm.idcard)
        setPassword(this.ruleForm.password)
      }
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.is_loading = true
          let params = {
            loginname: this.ruleForm.idcard,
            password: Encrypt(this.ruleForm.password),
            type: 1
          }
          login(params).then(response => {
            if (response.code === 0) {
              if (this.codeIsshow === true) {
                //最后在登录提交按钮中加入
                if (this.ruleForm.code !== '') {
                  if (this.ruleForm.code !== this.identifyCode.toLowerCase()) {
                    this.$message.error('请填写正确验证码')
                    // 重置验证码
                    //this.refreshCode()
                    return
                  }
                } else {
                  this.$message.error('请填写验证码')
                  return
                }
              }
              setIsPay(response.result.is_pay)
              setTerm(response.result.term)
              setResult(response.result)
              this.cookieData(response.result.licence_id)
              getSubject({ licence_id: response.result.licence_id }).then(
                res => {
                  if (res.code === 0) {
                    if (res.result.subject_list.length > 0) {
                      setSubjectId(res.result.subject_list[0].subject_id)
                      //获取用户车型
                      userlicence().then(res => {
                        if (res.code === 0) {
                          //setLicenceName(res.result[0].data[0].licence_name)
                          //setTerm(res.result[0].data[0].term[0].term)
                          for (let i = 0; i < res.result.length; i++) {
                            for (
                              let s = 0;
                              s < res.result[i].data.length;
                              s++
                            ) {
                              if (
                                JSON.parse(getResult()).licence_id ===
                                res.result[i].data[s].licence_id
                              ) {
                                setParentId(res.result[i].parent_id)
                                setLicenceName(
                                  res.result[i].data[s].licence_name
                                )
                              }
                            }
                          }
                          //setParentId(res.result[0].parent_id)
                          this.is_loading = false
                          if (getLxQuestionId() != undefined) {
                            this.$router.push({
                              path:
                                '/exam/subject?licence_id=' +
                                getLxLicenceId() +
                                '&subject_id=' +
                                getLxSubjectId()
                            })
                          } else {
                            this.$router.push({ path: '/progress' })
                          }
                        }
                      })
                    }
                  }
                }
              )
            } else if (response.code === -1) {
              this.is_loading = false
              this.loginstatus = false
              if (this.ruleForm.idcard.length != 18) {
                this.supplyForm.idcard = ''
              } else {
                this.supplyForm.idcard = this.ruleForm.idcard
              }
              this.supply = true
              this.getLicence()
              this.getcity()
            } else {
              this.is_loading = false
              this.$message({
                message: response.msg,
                type: 'error'
              })
              this.num++
            }
          })
        } else {
          return false
        }
      })
    },
    // resetForm(formName) {
    //   this.$refs[formName].resetFields()
    // },
    getLicence() {
      getlicence().then(res => {
        if (res.code === 0) {
          for (let i = 0; i < res.result.length; i++) {
            if (res.result[i].hasOwnProperty('children') === true) {
              for (let s = 0; s < res.result[i].children.length; s++) {
                if (
                  res.result[i].children[s].hasOwnProperty('sublist') === true
                ) {
                  res.result[i].children[s].children = []
                  for (
                    let t = 0;
                    t < res.result[i].children[s].sublist.length;
                    t++
                  ) {
                    let obj = {}
                    obj.licence_name = res.result[i].children[s].sublist[t]
                    obj.licence_id = res.result[i].children[s].sublist[t]
                    res.result[i].children[s].children.push(obj)
                  }
                }
              }
            }
          }
          this.licencedata = res.result
        }
      })
    },
    getcity() {
      getregion().then(res => {
        if (res.code === 0) {
          this.citydata = res.result
        }
      })
    },
    handleChangelicence(value) {
      this.supplyForm.licence_id = value[1]
      this.supplyForm.licence_title = value[2]
    },
    handleChangecity(value) {
      this.supplyForm.province_id = value[0]
      this.supplyForm.city_id = value[1]
    },
    supplySubmitForm(formName) {
      if (this.supplyForm.province_id == '' || this.supplyForm.city_id == '') {
        this.$message({
          message: '请选择地区',
          type: 'error'
        })
        return
      }
      if (this.supplyForm.licence_id == '') {
        this.$message({
          message: '请选择车型',
          type: 'error'
        })
        return
      }
      if (this.supplyForm.realname == '') {
        this.$message({
          message: '请输入姓名',
          type: 'error'
        })
        return
      }
      if (this.supplyForm.idcard == '') {
        this.$message({
          message: '请输入身份证号',
          type: 'error'
        })
        return
      }
      //term
      this.supplyForm.register_type = 1
      this.supplyForm.term = 1
      //this.supplyForm.register_ip = returnCitySN['cip']
      this.supplyForm.register_ip = ''
      if (this.supplyForm.licence_title === undefined) {
        this.supplyForm.licence_title = ''
      }
      //console.log(this.supplyForm)
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.subloading = true
          register(this.supplyForm).then(res => {
            if (res.code === 0) {
              setIsPay(res.result.is_pay)
              setTerm(res.result.term)
              setResult(res.result)
              this.cookieData(res.result.licence_id)
              getSubject({ licence_id: this.supplyForm.licence_id }).then(
                res => {
                  if (res.code === 0) {
                    if (res.result.subject_list.length > 0) {
                      setSubjectId(res.result.subject_list[0].subject_id)
                      //获取用户车型
                      userlicence().then(res => {
                        if (res.code === 0) {
                          //setLicenceName(res.result[0].data[0].licence_name)
                          //setTerm(res.result[0].data[0].term[0].term)
                          for (let i = 0; i < res.result.length; i++) {
                            for (
                              let s = 0;
                              s < res.result[i].data.length;
                              s++
                            ) {
                              if (
                                JSON.parse(getResult()).licence_id ===
                                res.result[i].data[s].licence_id
                              ) {
                                setParentId(res.result[i].parent_id)
                                setLicenceName(
                                  res.result[i].data[s].licence_name
                                )
                              }
                            }
                          }
                          //setParentId(res.result[0].parent_id)
                          // this.$router.push({ path: '/progress' })
                          this.subloading = false
                          if (getLxQuestionId() != undefined) {
                            this.$router.push({
                              path:
                                '/exam/subject?licence_id=' +
                                getLxLicenceId() +
                                '&subject_id=' +
                                getLxSubjectId()
                            })
                          } else {
                            this.$router.push({ path: '/progress' })
                          }
                        }
                      })
                    }
                  }
                }
              )
            } else if (res.code === 1) {
              this.subloading = false
              this.$message({
                message: res.msg,
                type: 'error'
              })
            } else if (res.code == 2) {
              this.subloading = false
              this.$message({
                message: res.msg,
                type: 'error'
              })
            }
          })
        }
      })
    },
    //需存入cookie数据
    cookieData(licence_id) {
      getSubject({ licence_id: licence_id }).then(res => {
        if (res.code === 0) {
          if (res.result.length > 0) {
            setSubjectId(res.result[0].subject_id)
            //获取用户车型
            userlicence().then(res => {
              if (res.code === 0) {
                //setLicenceName(res.result[0].data[0].licence_name)
                //setTerm(res.result[0].data[0].term[0].term)
                for (let i = 0; i < res.result.length; i++) {
                  for (let s = 0; s < res.result[i].data.length; s++) {
                    if (
                      JSON.parse(getResult()).licence_id ===
                      res.result[i].data[s].licence_id
                    ) {
                      setParentId(res.result[i].parent_id)
                      setLicenceName(res.result[i].data[s].licence_name)
                    }
                  }
                }
                //setParentId(res.result[0].parent_id)
                // this.$router.push({ path: '/progress' })
                if (getLxQuestionId() != undefined) {
                  this.$router.push({
                    path:
                      '/exam/subject?licence_id=' +
                      getLxLicenceId() +
                      '&subject_id=' +
                      getLxSubjectId()
                  })
                } else {
                  this.$router.push({ path: '/progress' })
                }
              }
            })
          }
        }
      })
    },
    handleClose(done) {
      clearInterval(this.timer)
      this.timer = 0
      this.is_sms = true
      this.is_time = false
      this.isCounting = false
      // 调用 done() 方法来关闭对话框
      done()
    },
    setnewpwd() {
      this.setform = {
        idcard: this.ruleForm.idcard,
        phone: '',
        code: '',
        password: ''
      }
      clearInterval(this.timer)
      this.timer = 0
      this.is_sms = true
      this.is_time = false
      this.isCounting = false
      this.is_setslider = false
      this.setDialog = true
    },
    setgetcode() {
      if (this.setform.idcard == '') {
        this.$message({
          message: '请您输入正确的身份证号/账号信息！',
          type: 'warning'
        })
        return
      }
      if (this.setform.phone == '' || this.setform.phone.length != 11) {
        this.$message({
          message: '请您输入正确的手机号！',
          type: 'warning'
        })
        return
      }
      this.getcode(1)
      //验证码倒计时
      if (this.isCounting) {
        return // 如果正在倒计时，则不执行重复的操作
      }
      this.isCounting = true
      this.countdown = 120
      this.timer = setInterval(() => {
        this.countdown--
        this.timeText = this.countdown + ' 秒'
        this.is_sms = false
        this.is_time = true
        if (this.countdown <= 0) {
          clearInterval(this.timer)
          this.isCounting = false
          this.is_sms = true
          this.is_time = false
          this.buttonText = '重新获取'
        }
      }, 1000)
    },
    setsubmit() {
      if (this.setform.idcard == '') {
        this.$message({
          message: '请您输入正确的身份证号/账号信息！',
          type: 'warning'
        })
        return
      }
      if (this.setform.phone == '' || this.setform.phone.length != 11) {
        this.$message({
          message: '请您输入正确的手机号！',
          type: 'warning'
        })
        return
      }
      if (this.setform.code == '') {
        this.$message({
          message: '请您输入正确的验证码！',
          type: 'warning'
        })
        return
      }
      const pwdrule = /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_]).{8,16}$/
      if (!pwdrule.test(this.setform.password)) {
        this.$message({
          message: '您密码设置格式不对，请您重新设置密码！',
          type: 'warning'
        })
      } else {
        if (this.is_setslider === true) {
          if (this.qrcode == 0) {
            //验证验证码
            let params = {
              phone: this.setform.phone,
              type: 1,
              code: this.setform.code
            }
            verifycode(params).then(res => {
              if (res.code === 0) {
                //服务端没有账号-》首次登陆
                this.setDialog = false
                this.loginstatus = false
                this.getLicence()
                this.getcity()
                if (this.setform.phone != '') {
                  this.supplyForm.phone = this.setform.phone
                }
                if (this.setform.password != '') {
                  this.supplyForm.password = this.setform.password
                }
                this.supplyForm.idcard = this.setform.idcard
                this.supply = true
              } else {
                if (res.code === 1) {
                  this.$message({
                    message: '验证码错误！',
                    type: 'warning'
                  })
                }
              }
            })
          } else if (this.qrcode == 3) {
            //服务端有账号第一次登录-》覆盖密码手机号
            let setparams = {
              phone: this.setform.phone,
              idcard: this.setform.idcard,
              newpwd: this.setform.password,
              code: this.setform.code,
              type: 1
            }
            resetpwd(setparams).then(res => {
              if (res.code === 0) {
                setIsPay(res.result.is_pay)
                setTerm(res.result.term)
                setResult(res.result)
                this.cookieData(res.result.licence_id)
                getSubject({ licence_id: res.result.licence_id }).then(res => {
                  if (res.code === 0) {
                    if (res.result.subject_list.length > 0) {
                      setSubjectId(res.result.subject_list[0].subject_id)
                      //获取用户车型
                      userlicence().then(res => {
                        if (res.code === 0) {
                          //setLicenceName(res.result[0].data[0].licence_name)
                          //setTerm(res.result[0].data[0].term[0].term)
                          for (let i = 0; i < res.result.length; i++) {
                            for (
                              let s = 0;
                              s < res.result[i].data.length;
                              s++
                            ) {
                              if (
                                JSON.parse(getResult()).licence_id ===
                                res.result[i].data[s].licence_id
                              ) {
                                setParentId(res.result[i].parent_id)
                                setLicenceName(
                                  res.result[i].data[s].licence_name
                                )
                              }
                            }
                          }
                          //setParentId(res.result[0].parent_id)
                          // this.$router.push({ path: '/progress' })
                          if (getLxQuestionId() != undefined) {
                            this.$router.push({
                              path:
                                '/exam/subject?licence_id=' +
                                getLxLicenceId() +
                                '&subject_id=' +
                                getLxSubjectId()
                            })
                          } else {
                            this.$router.push({ path: '/progress' })
                          }
                        }
                      })
                    }
                  }
                })
              } else {
                this.$message({
                  message: '失败！',
                  type: 'warning'
                })
              }
            })
          }
        } else {
          this.$message({
            message: '请完成滑块验证！',
            type: 'warning'
          })
        }
      }
    }
  },
  computed: {
    activeName: {
      get() {
        return this.active.toString()
      },
      set(newValue) {
        return newValue
      }
    }
  }
}
</script>
<style>
/* .login-header img {
  width: 217px;
} */
</style>
